import { MarketingPricing as Pricing } from "@/components/MarketingPricing/MarketingPricing.jsx";
import { MarketingFaq as Faq } from "@/components/MarketingFaq/MarketingFaq.jsx";
import { MarketingContact as Contact } from "@/components/MarketingContact/MarketingContact.jsx";
import { MarketingPreview as Preview } from "@/components/MarketingPreview/MarketingPreview.jsx";
import Footer  from "@/components/Footer/Footer.tsx";
import ButtonPushable from "@/components/buttons/ButtonPushable/ButtonPushable.tsx";
import Link from "next/link";

const Welcome = () => {
    return (
        <div className="wrapper marketing">
            <header className="main">
                <h2 className="main">Minecraft Skin Generator</h2>
                <div className="menu">
                    <a className="menu-text" href="#pricing">
                        Plans
                    </a>
                    <a className="menu-text" href="#faq">
                        FAQ
                    </a>
                    <a className="menu-text" href="#about">
                        About
                    </a>
                </div>
            </header>

            <section className="hero">
                <div className="content">
                    <div className="left">
                        <Preview />
                    </div>
                    <div className="right">
                        <p className="tagline">
                            Create custom skins from a text prompt
                        </p>
                        <Link className="cta" href="/login">
                            <ButtonPushable variant="large">
                                        <span>Generate Skins Now!</span>
                            </ButtonPushable>
                        </Link>
                    </div>
                </div>
            </section>

            <section id="pricing" className="pricing">
                <h3 className="main">Plans</h3>
                <Pricing />
            </section>

            <section id="faq" className="faq">
                <h3 className="main">FAQ</h3>
                <Faq />
            </section>

            <section id="about" className="about">
                <h3 className="main">About</h3>
                <p className="secondary">
                    The Minecraft Skin Generator uses custom generative models to
                    create skins for video games. With a simple text prompt
                    you can create characters quickly and easily. You're limited
                    only by your imagination!
                </p>
            </section>

            <section id="contact" className="contact">
                <Contact />
            </section>

            <div className="signature flex white w-full justify-center">
              <a href="https://www.monadical.com" target="_blank" rel="noreferrer">Made with ♥️ by Monadical</a>
            </div>

            <section id="footer">
                <Footer hideSocialIcons={false} hideBackground={true} />
            </section>
        </div>
    );
};

export default Welcome;
