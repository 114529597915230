import React from "react";
import Link from "next/link";

import Brand from "../Typography/Brand/Brand";
import styles from "./Footer.module.scss";
import { DiscordIcon } from "@/components/icons/discord";

interface FooterProps {
  hideSocialIcons?: boolean;
  hideBackground?: boolean;
}

const Footer = ({ hideSocialIcons, hideBackground }: FooterProps) => {
  return (
    <footer
      className={
        hideBackground
          ? `${styles.footerContainer} ${styles.hideBackground}`
          : styles.footerContainer
      }
    >
      <div className={styles.leftContent}>
        <Brand styles={{ fontSize: "20px" }} />
      </div>

      <div className={styles.middleContent}>
        <span>
          <a className="main underline" href="mailto:info@skingenerator.io">
            Contact Us
          </a>
        </span>

        <div className={styles.links}>
          <Link className="main underline" href="/terms">
            Terms of Service
          </Link>{" "}
          <span className={styles.divider}>| </span>
          <Link className="main underline" href="/acceptable-use-policy">
            Acceptable Use Policy
          </Link>{" "}
          <span className={styles.divider}>| </span>
          <Link className="main underline" href="/privacy-policy">
            Privacy Policy
          </Link>{" "}
          <span className={styles.divider}>| </span>
          <Link className="main underline" href="/cookie-policy">
            Cookie Policy
          </Link>
        </div>
      </div>

      {!hideSocialIcons && (
        <div className={styles.rightContent}>
          <a
            href="https://discord.gg/yMzFzVUPDf"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
        </div>
      )}
    </footer>
  );
};

export default Footer;
