import { useState } from "react";
import { ChevronRight } from "../MarketingIcons/ChevronRight";
import styles from "./faq.module.scss";

export function MarketingFaq() {
    const [visibleQuestions, setVisibleQuestions] = useState([
        false,
        false,
        false,
        false,
    ]);

    function toggleQuestion(index) {
        setVisibleQuestions((prev) => {
            const copy = [...prev];
            copy[index] = !copy[index];
            return copy;
        });
    }

    return (
        <div>
            <div
                className={`${styles.question} ${
                    visibleQuestions[0] ? styles.visible : ""
                }`}
                onClick={() => toggleQuestion(0)}
            >
                <div className={styles.content}>
                    <p className="main">Is SkinGenerator.io associated with Microsoft or Mojang?</p>
                    <div className={styles.icon}>
                        <ChevronRight />
                    </div>
                </div>
                <div className={styles.answer}>
                    <p className="secondary">
                        No.  SkinGenerator.io is not an official Minecraft product, nor is it approved by or associated with Mojang or Microsoft.
                    </p>
                </div>
            </div>

            <div
                className={`${styles.question} ${
                    visibleQuestions[1] ? styles.visible : ""
                }`}
                onClick={() => toggleQuestion(1)}
            >
                <div className={styles.content}>
                    <p className="main">How does it work?</p>
                    <div className={styles.icon}>
                        <ChevronRight />
                    </div>
                </div>
                <div className={styles.answer}>
                    <p className="secondary">
		      The SkinGenerator starts with the latest generative art models, and then finetune our own models to generate usable in-game skins.  You provide a text prompt of what you've imagined, and we take care of the rest!
                    </p>
                </div>
            </div>

            <div
                className={`${styles.question} ${
                    visibleQuestions[2] ? styles.visible : ""
                }`}
                onClick={() => toggleQuestion(2)}
            >
                <div className={styles.content}>
                    <p className="main">How do I save and use my skins?</p>
                    <div className={styles.icon}>
                        <ChevronRight />
                    </div>
                </div>
                <div className={styles.answer}>
                    <p className="secondary">
		      There is a button to download the currently selected skin.  You can then use the downloaded skin file in game.
                    </p>
                </div>
            </div>

            <div
                className={`${styles.question} ${
                    visibleQuestions[3] ? styles.visible : ""
                }`}
                onClick={() => toggleQuestion(3)}
            >
                <div className={styles.content}>
                    <p className="main">
                        Why does my Minecraft skin look so weird?
                    </p>
                    <div className={styles.icon}>
                        <ChevronRight />
                    </div>
                </div>
                <div className={styles.answer}>
                    <p className="secondary">
                        You may not have given the generator enough information in your text prompt. Try being more specific: use descriptive language and more than single-word prompts. For example, instead of writing "snowman", you could try "snowman with a top hat and red scarf".
                    </p>
                </div>
            </div>

            <div
                className={`${styles.question} ${
                    visibleQuestions[4] ? styles.visible : ""
                }`}
                onClick={() => toggleQuestion(4)}
            >
                <div className={styles.content}>
                    <p className="main">
                        Are there other games available other than Minecraft?
                    </p>
                    <div className={styles.icon}>
                        <ChevronRight />
                    </div>
                </div>
                <div className={styles.answer}>
                    <p className="secondary">
		      Not yet, but we'll be adding more in the future!
                    </p>
                </div>
            </div>
        </div>
    );
}
