import { useState } from "react";
import jsonp from "jsonp";
import styles from "./contact.module.scss";

const mailchimpKey = "ec55ae85b9f7a00e7e633e319";

export function MarketingContact(_props) {
    const [fname, setFname] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    function handleSubmit(event) {
        event.preventDefault();
        if (!fname || !email) {
            setError("Please fill out all fields");
            return;
        }

        const mailchimpUrl = `https://skingenerator.us21.list-manage.com/subscribe/post-json?u=${mailchimpKey}&amp;id=d4c48972af&amp;f_id=00b3e4e6f0`;

        // @params url: string, opts?: {}, callback: () => void
        jsonp(
            `${mailchimpUrl}&EMAIL=${email}&NAME=${fname}`,
            { param: "c" },
            (err, data) => {
                const { msg } = data;
                if (err) {
                    setError(err);
                    return;
                }
                setSuccess(msg);
            }
        );
    }

    return (
        <div className={styles.row}>
            <div className={styles.left}>
              <p className="main">Subscribe for exclusive offers and updates</p>
	      <p className="secondary">We'll keep you up to date on new developments, including early access to new features and models.</p>
                <img className={styles.envelope} src="/images/marketing/envelope.png" alt="Image of an envelope" />
            </div>
            <div className={styles.right}>
                <form className={styles.contactForm} onSubmit={handleSubmit}>
                    <label htmlFor="fname">Name:</label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="given-name"
                        value={fname}
                        required
                        onChange={(event) => setFname(event.target.value)}
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        required
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    {error && <p className={styles.error}>{error}</p>}
                    {success && <p className={styles.success}>{success}</p>}
                    <button className="marketing" type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    );
}
