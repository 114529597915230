export const SaleIcon = () => (
    <svg
        width="75"
        height="75"
        viewBox="0 0 75 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M26.0539 73.5H5C3.067 73.5 1.5 71.933 1.5 70L1.5 48.9461C1.5 48.0178 1.86875 47.1276 2.52513 46.4712L44.4001 4.59619C45.767 3.22935 47.983 3.22935 49.3499 4.59619L70.4038 25.6501C71.7706 27.017 71.7706 29.233 70.4038 30.5999L28.5288 72.4749C27.8724 73.1313 26.9822 73.5 26.0539 73.5ZM18.75 45.375C12.7653 45.375 7.875 50.2653 7.875 56.25C7.875 62.2347 12.7653 67.125 18.75 67.125C24.7347 67.125 29.625 62.2347 29.625 56.25C29.625 50.2653 24.7347 45.375 18.75 45.375Z"
            fill="#AA69F4"
            stroke="black"
            strokeWidth="3"
        />
        <path
            d="M35.646 45.7209C35.0835 46.2833 34.4882 46.696 33.86 46.959C33.2318 47.222 32.6036 47.3097 31.9753 47.222C31.3544 47.127 30.7847 46.8348 30.266 46.3454L32.2602 44.3512C32.5743 44.6069 32.8921 44.7274 33.2135 44.7128C33.5276 44.6909 33.8198 44.5448 34.0901 44.2745C34.3677 43.9969 34.5247 43.7157 34.5612 43.4308C34.5905 43.1386 34.4919 42.8793 34.2654 42.6528C34.0755 42.4629 33.8527 42.3716 33.597 42.3789C33.3486 42.3789 33.0893 42.4337 32.819 42.5433C32.5561 42.6455 32.2018 42.8172 31.7562 43.0582C31.1061 43.4016 30.5472 43.6536 30.0797 43.8143C29.6122 43.975 29.1155 44.0188 28.5896 43.9458C28.0636 43.8727 27.5596 43.5952 27.0775 43.113C26.3616 42.3972 26.0621 41.579 26.179 40.6586C26.2885 39.7309 26.7597 38.8507 27.5924 38.0179C28.4398 37.1706 29.3273 36.6921 30.2551 36.5825C31.1755 36.4656 32.0155 36.7505 32.7752 37.4372L30.7481 39.4643C30.4852 39.2305 30.2003 39.1283 29.8935 39.1575C29.5794 39.1794 29.2908 39.3218 29.0278 39.5848C28.8014 39.8113 28.6809 40.056 28.6663 40.3189C28.6443 40.5746 28.7466 40.8157 28.9731 41.0421C29.2214 41.2905 29.5319 41.3672 29.9044 41.2722C30.277 41.1773 30.792 40.9618 31.4494 40.6257C32.1141 40.297 32.6766 40.056 33.1368 39.9026C33.6043 39.7419 34.0974 39.6944 34.616 39.7601C35.1347 39.8259 35.6241 40.0888 36.0843 40.549C36.5226 40.9873 36.8075 41.4987 36.939 42.0831C37.0778 42.6601 37.0412 43.2664 36.8294 43.902C36.6176 44.5375 36.2231 45.1438 35.646 45.7209Z"
            fill="#EBDA45"
        />
        <path
            d="M42.9571 35.539L40.0863 38.4098L40.9848 40.2287L39.0234 42.1901L34.1146 31.7149L36.2841 29.5454L46.7592 34.4543L44.776 36.4375L42.9571 35.539ZM41.0286 34.5748L37.2593 32.7121L39.133 36.4704L41.0286 34.5748Z"
            fill="#EBDA45"
        />
        <path
            d="M48.0336 30.2872L50.488 27.8327L51.9344 29.2791L47.6063 33.6072L39.9143 25.9152L41.788 24.0415L48.0336 30.2872Z"
            fill="#EBDA45"
        />
        <path
            d="M48.5109 20.3209L50.0668 21.8768L52.5761 19.3676L54.0224 20.814L51.5132 23.3232L53.2006 25.0106L56.0385 22.1726L57.5397 23.6738L52.8281 28.3854L45.1361 20.6934L49.8477 15.9818L51.3488 17.483L48.5109 20.3209Z"
            fill="#EBDA45"
        />
    </svg>
);
