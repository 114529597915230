import { useState, useEffect } from "react";
import styles from "./preview.module.scss";

const images = [
    "preview_1.png",
    "preview_2.png",
    "preview_3.png",
    "preview_4.png",
    "preview_5.png",
];

export function MarketingPreview() {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((index + 1) % images.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [index]);

    return (
        <div className={styles.preview}>
            <img className={styles.image} src={"/images/marketing/" + images[index]} alt="Preview of a Minecraft skin" />
        </div>
    );
}
