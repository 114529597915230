import styles from './ButtonPushable.module.scss';

interface ButtonPushableProps {
    variant?: "default" | "large";
    children: React.ReactNode;
}

const ButtonPushable = (props: ButtonPushableProps) => {
    return (
        <div className={styles[props.variant || "default"]}>
            <div role="button" className={styles.pushable}>
                <span className={styles.edge}></span>
                    <span className={styles.front}>
                        {props.children}
                    </span>
            </div>
        </div>
    )
};

export default ButtonPushable;
