import React, { CSSProperties } from "react";
import styles from "./Brand.module.scss";

interface BrandProps {
  onClick?: () => void;
  styles?: CSSProperties;
  variant?: "default" | "white" | "large";
}

const Brand = (props: BrandProps) => {
  return (
    <h2
      onClick={props.onClick}
      className={`brand ${styles[props.variant || "default"]}`}
      style={props.styles}
    >
    Minecraft Skin Generator
    </h2>
  );
};

export default Brand;
