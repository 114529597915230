export function ChevronRight() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 40 40"
            fill="none"
        >
            <path
                d="M15 10L25 20L15 30"
                stroke="#b4b4b4"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
