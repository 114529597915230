import React from "react";
import Link from "next/link";
import pricingData from "./pricing-data.json";
import styles from "./pricing.module.scss";
import { SaleIcon } from "@/components/icons/sale.tsx";

export function MarketingPricing(_props) {
    return (
        <div className={styles.columns}>
            {pricingData.map((data) => (
                <div
                    key={data.tier}
                    className={
                        data.highlight
                            ? `${styles.column} ${styles.highlight}`
                            : styles.column
                    }
                >
                    {data.sale && (
                        <div className={styles.sale}>
                            <SaleIcon />
                        </div>
                    )}
                    <p className={styles.main}>{data.tier}</p>
                    <p className={styles.secondary}>{data.price}</p>
                    <Link href="/login" className={styles.cta}>
                        <button className="small marketing">{data.cta}</button>
                    </Link>

                    <div className={styles.features}>
                        {data.features.map((feature, index) => (
                            <React.Fragment key={index}>
                                <div className={styles.feature}>
                                    {feature.map((f, i) => (
                                        <React.Fragment key={i}>
                                            <span
                                                className={
                                                    f.bold
                                                        ? styles.bold
                                                        : styles.body
                                                }
                                            >
                                                {f.text}{" "}
                                            </span>
                                        </React.Fragment>
                                    ))}
                                </div>
                                {index < data.features.length - 1 && (
                                    <div className={styles.divider}></div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
